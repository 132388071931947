@import "@/assets/scss/style.scss";

.statistics-wrapper {
    width: 80%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    overflow-x: auto;
    max-height: 80vh;
    margin: 5rem 0;
    border: 1px solid #d3d3ff;
    border-radius: 4px;
    padding: 2rem;

    @media (max-width: 1400px) {
        width: 85%;
    }

    @media (max-width: 1100px) {
        width: 90%;
    }

    .statistics__type-title {
        @extend .label-style;
    }

    .statistics__type-dropdown {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .statistics__form-group {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;

        input {
            width: 100%;
            outline: 0;
            border-width: 0 0 1px;
            border-color: rgb(212, 212, 212);
        }

        input::focus,
        input::hover,
        input::active {
            outline: 0;
            border-color: rgb(85, 85, 85);
        }

        span {
            @extend .label-style;
        }

        .statistics__is-default {
            width: 1.2rem;
            height: 1.2rem;
            cursor: pointer;
        }
    }

    .statistics__table-wrap {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        span {
            margin-bottom: 1rem;
        }

        .ant-table-wrapper {
            width: 100%;
            overflow-x: auto;
        }
    }

    .label-style {
        margin-right: 0.7em;
        color: rgb(34, 34, 34);
        font-size: 1rem;
        white-space: nowrap;
    }

    .select-users {
        min-width: 20rem;
        font-weight: normal;
        max-height: 8rem;
        overflow-y: auto;
    }
}
