@import "@/assets/scss/style.scss";

.statistics-cms-wrapper {
    margin: 5rem auto;
    width: 50vw;
    border: 1px solid rgb(212, 212, 212);

    @media (max-width: 1400px) {
        width: 60%;
    }

    @media (max-width: 1100px) {
        width: 70%;
    }

    @media (max-width: 800px) {
        width: 80%;
    }

    @media (max-width: 600px) {
        width: 95%;
    }

    .statistics__top-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0.5rem;
        border-bottom: 1px solid rgb(212, 212, 212);
        padding-bottom: 0.5rem;

        @media (max-width: 650px) {
            flex-direction: column;
        }

        .statistics__button-wrapper {
            display: flex;
            align-items: center;

            .statistics__create-button {
                margin-right: 0.5rem;
            }
        }

        .statistics__title {
            color: rgb(45, 45, 45);
            margin-left: 45%;
            font-size: 1rem;

            @media (max-width: 650px) {
                margin: 0 0 1rem 0;
            }
        }

        button {
            font-size: 1rem;
        }
    }

    .statistics-table-wrapper {
        overflow-x: auto;
    }

    .statistics-table {
        min-width: max-content;
    }
    .statistics-cms-content {
        padding: 2rem;
    }

    .delete-icon {
        font-size: 20px;
        color: red;
    }

    .reports__table-row {
        cursor: pointer;
    }
}

.statistics-modal {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-modal-body {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
